import inView from 'in-view';

function init() {

  const estateAnchorNav = document.querySelector('.c-estate-anchor-nav');

  if (!estateAnchorNav) {
    return;
  }

  /**
   * Handle sticky status
   * @link https://stackoverflow.com/a/62359671
   */
  new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const stickyEl = entry.target.nextElementSibling
        stickyEl.classList.toggle('stuck', !entry.isIntersecting)
      }
    },
    {}
  ).observe(estateAnchorNav.previousElementSibling)

  /**
   * Handle current link
   */
  const navLinkEls = estateAnchorNav.querySelectorAll('a');
  let sectionIds = [...navLinkEls].map((el) => {
    const sectionEl = document.querySelector(el.hash);
    return sectionEl ? sectionEl.id : '';
  });

  sectionIds = sectionIds.filter(id => id && id.trim().length);

  inView.threshold(0.20);

  sectionIds.forEach((sectionId) => {
    if (!document.querySelector(`#${sectionId}`)) {
      return;
    }
    inView(`#${sectionId}`)
      .on('enter', (el) => {
        const enteredEls = document.querySelectorAll(`nav a[href*="#${el.id}"]`);

        [...document.querySelectorAll('nav a')].forEach((linkEl) => {
          linkEl.classList.remove('is-current');
        });

        enteredEls.forEach(linkEl => linkEl.classList.add('is-current'));
      })
      .on('exit', (el) => {
        const exitedEls = document.querySelectorAll(`nav a[href*="#${el.id}"]`);
        exitedEls.forEach(linkEl => linkEl.classList.remove('is-current'));
      });
  });

}

export default {
  init,
}
