function init() {
  $('.b-photo-gallery').each(function() {
    $(this).find('a').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true,
      },
      removalDelay: 300,
      mainClass: 'mfp-fade',
    });
  })
}

export default {
  init,
};
