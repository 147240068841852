import googleMapsApiLoader from 'google-maps-api-loader';

let apiKey = '';

function mount(mapEl, googleApi) {
  const mapCanvasEl = mapEl.querySelector('.b-google-map__canvas') || [];
  const markerEls = mapEl.querySelectorAll('.b-google-map__item');

  const initialCenter = {
    lat: Number(markerEls[0].dataset.lat),
    lng: Number(markerEls[0].dataset.lng),
  };

  const bounds = new googleApi.maps.LatLngBounds();
  const infowindow = new googleApi.maps.InfoWindow();
  const map = new googleApi.maps.Map(mapCanvasEl, {
    center: initialCenter,
    zoom: 13,
    // disableDefaultUI: true,
    // gestureHandling: 'none',
    // zoomControl: false,
  });

  // Set map style
  try {
    const stylesArray = JSON.parse(mapEl.querySelector('.b-google-map__map-style-data').textContent);
    const styledMapType = new googleApi.maps.StyledMapType(stylesArray, { name: 'custom-map' });
    // Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  } catch (error) {
    // console.err(error);
  }

  // Custom marker icon from Material Design
  const pinSVGHole = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z';
  const markerImage = {
    path: pinSVGHole,
    anchor: new googleApi.maps.Point(12,17),
    fillOpacity: 1,
    fillColor: 'rgb(197, 43, 97)',
    strokeWeight: 1,
    strokeColor: 'white',
    scale: 2,
  };

  markerEls.forEach((markerEl) => {
    const marker = new googleApi.maps.Marker({
      position: {
        lat: Number(markerEl.dataset.lat),
        lng: Number(markerEl.dataset.lng),
      },
      title: markerEl.dataset.name,
      icon: markerImage,
      // map,
    });

    marker.setMap(map);

    bounds.extend(marker.getPosition());

    marker.addListener('click', () => {
      infowindow.setContent(markerEl.innerHTML);
      infowindow.open(map, marker);
    });

    return marker;
  });

  googleApi.maps.event.addListenerOnce(map, 'bounds_changed', () => {
    if (map.getZoom() > 13) {
      map.setZoom(13);
    }
  });

  map.fitBounds(bounds);
}

function init() {
  const mapEls = document.querySelectorAll('.b-google-map') || [];

  if (!mapEls.length) {
    return;
  }

  apiKey = mapEls[0].querySelector('.b-google-map__canvas').dataset.apiKey;

  googleMapsApiLoader({
    libraries: ['places'],
    apiKey,
    language: document.documentElement.lang,
  }).then((googleApi) => {
    mapEls.forEach((mapEl) => {
      let mounted = false;
      const inviewObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.1 && !mounted) {
            mounted = true;
            mount(mapEl, googleApi);
          }
        });
      }, { threshold: [0.1] });

      inviewObserver.observe(mapEl);
    });
  }, (err) => {
    console.error(err);
  });

}

export default {
  init,
};
