/* global $ */

function init() {

  $('a.js-vr-tour').magnificPopup({
    type: 'iframe',
    mainClass: 'vr-tour-viewer',
  });

}

export default {
  init,
};
