/* global $ */

function init() {
  $('.c-apartment-card .a-media a[href], .c-apartment-tpl__floorplans a').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true,
    },
    image: {
      titleSrc: (item) => {
        const el = item.el[0];
        let markup = '';
        if (el.hasAttribute('title')) {
          markup += `<span>${el.getAttribute('title')}</h3>`;
        }
        if (el.dataset.link) {
          markup += `, <a href="${el.dataset.link}" class="text-green">Tutustu asuntoon</a>`;
        }
        return markup;
      },
    },
    mainClass: 'mfp-fade one-off--floorplan-viewer',
  });
}

export default {
  init,
};
