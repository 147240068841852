function loadSvg(blockEl) {
  const response = fetch(blockEl.dataset.svgUrl);
  return response
    .then((data) => {
      return data.text();
    }).then((svg) => {
      blockEl.querySelector('.b-estate-layout-map-vector__image').innerHTML = svg;
    });
}

function popoverClose() {
  const apartmentPathEls = document.querySelectorAll('.b-estate-layout-map-vector #varitys > *');
  const popoverEl = document.querySelector('.b-estate-layout-map-vector__popover');
  popoverEl.classList.add('hidden');
  apartmentPathEls.forEach((el) => el.classList.remove('apartment--active'));
}

function popoverShow({ clientX, clientY, currentTarget }) {
  const image = document.querySelector('.b-estate-layout-map-vector__image');
  const rect = image.getBoundingClientRect();
  const x = clientX - rect.left; //x position within the element.
  const y = clientY - rect.top;
  const left = x / rect.width * 100;
  const right = 100 - left;
  const top = y / rect.height * 100;
  const bottom = 100 - top;
  const id = currentTarget.getAttribute('id').toLowerCase();
  const popoverEl = document.querySelector('.b-estate-layout-map-vector__popover');
  const popoverBodyEl = document.querySelector('.b-estate-layout-map-vector__popover-body');
  const popoverContentEl = document.querySelector(`#b-estate-layout-map-vector__popover-content--${id}`);

  let align = 'center';

  if (left < 30) {
    align = 'left';
  } else if (right < 30) {
    align = 'right';
  }

  // Close earlier popover
  popoverClose();

  // Open new popover
  setTimeout(() => {
    popoverEl.setAttribute('data-align', align);
    popoverEl.classList.remove('hidden');
    popoverEl.style.setProperty('--inset-left', `${left}%`);
    popoverEl.style.setProperty('--inset-right', `${right}%`);
    popoverEl.style.setProperty('--inset-top', `${top}%`);
    popoverEl.style.setProperty('--inset-bottom', `${bottom}%`);
    popoverBodyEl.innerHTML = popoverContentEl.innerHTML;
    currentTarget.classList.add('apartment--active');
  }, 0 );

}

function mount(blockEl) {
  const areaEls = document.querySelectorAll('.b-estate-layout-map-vector #varitys > *');
  const popoverEl = document.querySelector('.b-estate-layout-map-vector__popover');

  // Only show current apartment if shown in single apartment-view
  if (blockEl.classList.contains('has--initial-apartment')) {
    const initialApartmentFlatnumber = blockEl.dataset.initialApartmentFlatnumber;
    const initialApartmentPath = document.querySelector(`.b-estate-layout-map-vector #varitys #hallinnanjako-asunto-${initialApartmentFlatnumber}`);
    initialApartmentPath.classList.add('apartment--active');
    // RETURN EARLY
    return;
  }

  // Handle popover close-button
  popoverEl.querySelector('button').addEventListener('click', popoverClose);

  // Close popover on outside click
  document.documentElement.addEventListener('click', (event) => {
    if (!popoverEl.contains(event.target)) {
      popoverClose();
    }
  });

  // Add area event handlers
  areaEls.forEach((el) => {
    const id = el.getAttribute('id').toLowerCase();
    const popoverContentEl = document.querySelector(`#b-estate-layout-map-vector__popover-content--${id}`);

    if (!popoverContentEl) {
      console.error(`Map popover content for ${id} not found. Something is wrong.`);
      return;
    }

    el.style.setProperty(`--status-color`, `var(--colors-${popoverContentEl.dataset.statusColor})`);
    el.addEventListener('click', popoverShow);
  });

}

function init() {
  const blockEl = document.querySelector('.b-estate-layout-map-vector');

  if (!blockEl) {
    return;
  }

  // Load & mount when user scrolls close to the block
  let mounted = false;
  const inviewObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio >= 0.1 && !mounted) {
        mounted = true;
        loadSvg(blockEl).then(() => {
          mount(blockEl);
        });
      }
    });
  }, { threshold: [0.1] });

  inviewObserver.observe(blockEl);
}

export default {
  init,
}
