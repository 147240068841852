/**
 * @link https://stackoverflow.com/a/48370297
 */
 class ResponsiveImageMap {
  constructor(map, img, width) {
    this.img = img;
    this.originalWidth = width;
    this.areas = [];

    for (const area of map.getElementsByTagName('area')) {
      this.areas.push({
        element: area,
        originalCoords: area.coords.split(',')
      });
    }

    window.addEventListener('resize', e => this.resize(e));
    this.resize();
  }

  resize() {
    const ratio = this.img.offsetWidth / this.originalWidth;

    for (const area of this.areas) {
      const newCoords = [];
      for (const originalCoord of area.originalCoords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };
}

function popoverClose() {
  const apartmentImageEls = document.querySelectorAll('.c-estate-layout-map__apartment');
  const popoverEl = document.querySelector('.c-estate-layout-map__popover');
  popoverEl.classList.add('hidden');
  apartmentImageEls.forEach((el) => el.classList.remove('apartment--active'));
}

function popoverShow({ clientX, clientY, target }) {
  const image = document.querySelector('#c-estate-layout-map__image');
  const rect = image.getBoundingClientRect();
  const x = clientX - rect.left; //x position within the element.
  const y = clientY - rect.top;
  const left = x / rect.width * 100;
  const right = 100 - left;
  const top = y / rect.height * 100;
  const bottom = 100 - top;
  const id = target.getAttribute('alt').toLowerCase();
  const popoverEl = document.querySelector('.c-estate-layout-map__popover');
  const popoverBodyEl = document.querySelector('.c-estate-layout-map__popover-body');
  const popoverContentEl = document.querySelector(`#c-estate-layout-map__popover-content-${id}`);
  const apartmentImageEl = document.querySelector(`#c-estate-layout-map__apartment-${id.toLowerCase()}`);

  let align = 'center';

  if (left < 30) {
    align = 'left';
  } else if (right < 30) {
    align = 'right';
  }

  popoverClose();

  setTimeout(() => {
    popoverEl.setAttribute('data-align', align);
    popoverEl.classList.remove('hidden');
    popoverEl.style.setProperty('--inset-left', `${left}%`);
    popoverEl.style.setProperty('--inset-right', `${right}%`);
    popoverEl.style.setProperty('--inset-top', `${top}%`);
    popoverEl.style.setProperty('--inset-bottom', `${bottom}%`);
    popoverBodyEl.innerHTML = popoverContentEl.innerHTML;
    apartmentImageEl.classList.add('apartment--active');
  }, 0 );

}

function init() {
  const block = document.querySelector('.c-estate-layout-map');
  const map = document.querySelector('#c-estate-layout-map__map-areas');
  const image = document.querySelector('#c-estate-layout-map__image');
  const areaEls = document.querySelectorAll('.c-estate-layout-map area');
  const popoverEl = document.querySelector('.c-estate-layout-map__popover');

  if (!block) {
    return;
  }

  // Make imagemap responsive
  new ResponsiveImageMap(map, image, 763);

  // Only show current apartment if shown in single apartment-view
  if (block.classList.contains('has--initial-apartment')) {
    return;
  }

  // Handle popover close-button
  popoverEl.querySelector('button').addEventListener('click', popoverClose);

  // Close popover on outside click
  document.documentElement.addEventListener('click', (event) => {
    if (!popoverEl.contains(event.target)) {
      popoverClose();
    }
  });

  // Add area event handlers
  areaEls.forEach((el) => {

    el.addEventListener('click', popoverShow);

    el.addEventListener('mouseenter', () => {
      const id = el.getAttribute('title');
      const selector = `#c-estate-layout-map__apartment-${id.toLowerCase()}`;
      const apartmentImageEl = document.querySelector(selector);
      const apartmentImageElActive = document.querySelector('.c-estate-layout-map .apartment--hover-active');

      if (!apartmentImageEl) {
        return;
      }

      if (apartmentImageEl.classList.contains('apartment--hover-active')) {
        // nothing
      } else {
        apartmentImageElActive && apartmentImageElActive.classList.remove('apartment--hover-active');
        apartmentImageEl.classList.add('apartment--hover-active');
      }
    })

    el.addEventListener('mouseleave', () => {
      const id = el.getAttribute('title');
      const selector = `#c-estate-layout-map__apartment-${id.toLowerCase()}`;
      const apartmentImageEl = document.querySelector(selector);
      apartmentImageEl.classList.remove('apartment--hover-active');
    })

  });

}

export default {
  init,
}
